import axios from "../../utils/request";
//获取用户列表
export function getUsersList(data) {
  return axios({
    url: "/clodchainAM/web/user/queryUsers",
    method: "get",
    params: data
  });
}
//获取角色列表
export function getRoleList(data) {
  return axios({
    url: "/clodchainAM/web/role/roleForDept",
    method: "get",
    params: data
  });
}

//删除
export function deleteUsers(data) {
  return axios({
    url: "/clodchainAM/web/user/delUsers",
    method: "post",
    data: data
  });
}
//获取当前用户组织
export function getDept(data) {
  return axios({
    url: "/clodchainAM/web/dept/queryDeptAll",
    method: "get",
    params: data
  });
}

//创建用户
export function daaUser(data) {
  return axios({
    url: "/clodchainAM/web/user/createUsers",
    method: "post",
    data: data,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
    }
  });
}
export function userQueryUserInfo(userId) {
  return axios({
    url: `/clodchainAM/web/user/queryUserInfo/${userId}`,
    method: "get",
    params: {}
  });
}

//创建用户
export function userUsers(data) {
  return axios({
    url: "/clodchainAM/web/user/users",
    method: "post",
    ...data
  });
}

//获取用户角色
export function getRole(data) {
  return axios({
    url: "/clodchainAM/web/role/quUserRole",
    method: "get",
    params: data
  });
}
//获取角色列表
export function roleQuUserRole(data) {
  return axios({
    url: "/clodchainAM/web/role/quUserRole",
    method: "get",
    ...data
  });
}

//添加角色

export function roleRrUserRole(data) {
  return axios({
    url: "/clodchainAM/web/role/crUserRole",
    method: "post",
    ...data
  });
}

//删除角色

export function roleDelRole(data) {
  return axios({
    url: "/clodchainAM/web/role/delRole",
    method: "post",
    ...data
  });
}
