<template>
  <div>
    <div>
      <el-input
        class="condition_input"
        placeholder="请输入关键字"
        v-model="queryData.keyword"
      ></el-input>
      <el-button class="query_button query" @click="roleQuUserRole"
        >查询</el-button
      >
      <el-button class="import" icon="el-icon-download" @click="add"
        >添加</el-button
      >
      <el-button
        class="import"
        icon="el-icon-delete"
        @click="roleDelRole(false)"
        :disabled="!ids.length"
        >删除</el-button
      >
    </div>
    <div class="table">
      <el-table
        stripe
        :header-cell-style="{ background: '#E8EAEF', color: '#3D3D3D' }"
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        :header-row-class-name="tableRowClassName"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column prop="roleName" label="角色名称"> </el-table-column>
        <el-table-column prop="roleDesc" label="角色描述"> </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button
              type="text"
              class="edit el-icon-edit"
              :disabled="redactForbidden"
              size="small"
              @click="handleEdit(scope.row)"
              >编辑</el-button
            >
            <i class="app-operation-line" />
            <el-button
              type="text"
              size="small"
              style="color: #F56C6C;font-size: 14px"
              @click="roleDelRole(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="app-page-container">
      <span @click="first()">首页</span>
      <el-pagination
        @current-change="handleCurrentChange"
        layout="pager"
        :hide-on-single-page="false"
        :page-size="pageSize"
        :current-page.sync="currentPage"
        :total="recordCount"
      >
      </el-pagination>
      <span @click="last()">尾页</span>
    </div>
  </div>
</template>

<script>
import { roleDelRole, roleQuUserRole } from "@/api/systemAdmin/userAdmin";
const defaultForm = {
  roleId: "",
  type: "添加"
};
export default {
  name: "roleControl",
  data() {
    return {
      redactForbidden: false,
      tableData: [],
      currentPage: 1,
      pages: 1,
      pageSize: 12, //每页数据条数
      recordCount: 1,
      queryData: {
        keyword: ""
      },
      formData: {
        ...defaultForm
      },
      ids: []
    };
  },
  created() {
    this.roleQuUserRole();
  },
  methods: {
    //分页首页
    first() {
      this.currentPage = 1;
      this.pages = 1;
      this.roleQuUserRole();
    },
    //分页最后一页
    last() {
      this.currentPage = this.recordCount;
      this.pages = Math.ceil(this.recordCount / 12);
      this.roleQuUserRole();
    },
    handleCurrentChange(val) {
      this.pages = val;
      this.roleQuUserRole();
    },
    // 添加
    add() {
      this.formData.type = "添加";
      this.$router.push({
        path: "/dashboard/systemSet/addRoleControl",
        query: { ...this.formData }
      });
    },
    //编辑
    handleEdit(row) {
      this.formData.type = "编辑";
      this.$router.push({
        path: "/dashboard/systemSet/addRoleControl",
        query: Object.assign(this.formData, row)
      });
    },
    //获取角色列表
    roleQuUserRole() {
      const config = {
        params: {
          userId: localStorage.getItem("userId"),
          page: this.pages,
          limit: 12,
          ...this.queryData
        }
      };
      roleQuUserRole(config).then(res => {
        this.tableData = res.data.data.list;
        this.recordCount = res.data.data.total;
      });
    },
    //删除角色
    roleDelRole(datas) {
      const config = {
        data: {
          ids: []
        }
      };
      if (datas) {
        config.data.ids = [datas.roleId];
      } else {
        this.ids.forEach(el => {
          config.data.ids.push(el.roleId);
        });
      }
      roleDelRole(config).then(({ data }) => {
        if (data.resultCode === 0) {
          this.$message({
            showClose: true,
            message: "删除成功",
            type: "success"
          });
          this.roleQuUserRole();
        } else {
          this.$message({
            showClose: true,
            message: data.desc || "删除失败",
            type: "error"
          });
        }
      });
    },
    //选择框
    handleSelectionChange(val) {
      this.ids = val;
    },
    //获取表格行内容
    tableRowClassName(row, rowIndex) {
      if (rowIndex == 0) return "headerStyle";
    }
  }
};
</script>

<style scoped>
.app-operation-line {
  display: inline-block;
  border-right: 1px solid #e2e2e2;
  height: 13px;
  margin: 0 20px;
}
.edit {
  font-size: 14px;
  color: #6e7790;
}
.edit {
  font-size: 14px;
  color: #6e7790;
}
.edit:hover {
  color: #0cc;
}
.el-icon-edit:hover {
  color: #0cc;
}

/*表格*/
.table {
  margin-top: 20px;
}
/*查询条件*/
.query_button {
  margin-left: 10px;
}
.condition_input {
  width: 246px;
}
</style>
